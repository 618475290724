<template>
        <!-- 头部开始 -->
        <div class="pur-top">
            <span class="titleSpan">{{timedata}} </span>
            
        <!-- 内容开始 -->
        <div class="pur-nav">
            <div class="dataAva">
              <img src="../../assets/zanwushuju.png" alt="" />
              <p>云账务仪表盘正在开发中...</p>
            </div>
        </div>
        <!-- 内容结束 -->
    </div>
</template>
<script>
export default {
    data() {
        return {
            timedata:'',
        }
    },
    created() {
        // 根据系统不同时间来判断，所以需要用到日期内置对象
        // 2. 得到当前的小时数
        var h = new Date().getHours();
        // 3. 判断小时数改变文字信息
        if(h < 6){
            this.timedata = '深夜了 !';
        }else if (h < 9){
            this.timedata = '早上好 !';
        }else if (h < 12){
            this.timedata = '上午好 !';
        }else if (h < 14){
            this.timedata = '中午好 !';
        }else if (h < 18){
            this.timedata = '下午好 !';
        }else {
            this.timedata = '晚上好 !';
        };
    },
}
</script>
<style  lang="less" scoped>
   
    // 头部开始
    .pur-top {
        width: 100%;
        height: 50px;
        padding: 0 40px 0 32px;
        background-color: #fff;
        box-sizing: border-box;
        box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
        margin: 0 0 0.5% 0;
        .titleSpan {
            font-size: 14px;
            line-height: 50px;
            color: #333;
            font-weight: 600;
            margin-right:32px;
        }
    }
    // 头部结束
   
    // 内容开始
    .pur-nav {
        width:100%;
        height:calc(100vh - 123px);
        background: #fff;
        // overflow-x: hidden;
        // overflow-y: scroll;
        margin-top:4px;
        position: relative;

        .dataAva {
            width: 371px;
            height: 224px;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            text-align: center;
            img {
                width: 371px;
                height: 200px;
                margin: auto;
            }
            p {
                font-size: 16px;
                line-height: 24px;
                color: #333;
            }
            .el-button {
                margin-bottom: 100px;
            }
        }
    }
    // 内容结束
</style>